.colorfondoLanzarllamado{
  background-color: #f0f0f0;
  padding: 24px;
}

.contendorLanzaTuLlamado{
  padding: 13px;
  background: rgb(255, 255, 255);
  text-align: initial;
  border-radius: 13px;
  margin-bottom: 14px;
  min-height: 350px;
  /*margin-top: 14px;*/
  /*margin-left: 20px;*/
  /*margin-right: 20px;*/
}

.contenedorLogoEmpresa{
  margin-left: -34px;
  max-width: 280px;
  /*margin-bottom: 10px;*/
}
.tituloLanzaLlamado{
  font-weight: 900;
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 30px;
  /*margin: 15px 5px 12px;*/
}
#subtitulo{
  color: rgb(255, 87, 34);
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 28px;
  /*margin: 15px 5px 12px;*/
}
.styleBtnPostular{
  color: #ffff;
  border-radius: 15px;
  margin-right: 5px;
  font-weight: 900;
  background: rgb(255, 87, 34);
  text-align: center;
  height: 40px;
  padding-top: 5px;
  font-size: 12px;
}

.btnLlamado{
  margin-left: auto;
  margin-right: auto;
}

/* @media only screen and (min-width: 1901px) {
  .styleBtnPostular{
    color: #ffff;
    border-radius: 15px;
    margin-right: 5px;
    font-weight: 900;
    background: rgb(255, 87, 34);
    text-align: center;
    height: 100px;
    padding-top: 30px;
    font-size: 40px;
  }
} */

@media only screen and (max-width: 2560px) {
  .styleBtnPostular{
    border-radius: 5px;
    font-size: 20px !important;
    width: 500px;
  }
  .btnLlamado{
    margin-left: 40%;
  }
}

@media only screen and (max-width: 425px) {
  .styleBtnPostular{
    border-radius: 5px;
    font-size: 12px !important;
    width: 200px;
  }
  .btnLlamado{
    margin-left: 25%;
  }
}

@media only screen and (max-width: 375px) {
  .styleBtnPostular{
    border-radius: 5px;
    font-size: 12px !important;
    width: 200px;
  }
  .btnLlamado{
    margin-left: 25%;
  }
}

@media only screen and (max-width: 320px) {
  .styleBtnPostular{
    border-radius: 5px;
    font-size: 12px !important;
    width: 200px;
  }
  .btnLlamado{
    margin-left: 20%;
  }
}