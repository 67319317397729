/*========================
    Blog Details
==========================*/

.single-post-details {
    .content {
        p {
            line-height: 28px;
            font-size: 16px;
            &.first-bold {
                font-size: 18px;
                line-height: 1.94;
                font-weight: 500;
                color: #222222;
            }
        }
    }
    .quote-content {
        position: relative;
        margin: 37px 0 44px !important;
        padding: 0 0 0 53px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.625;
        &::before{
            content: "\f1b2";
            font-size: 26px;
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            font-family: 'Material-Design-Iconic-Font';
            font-weight: 900;
            color: $theme-color;
        }
        .quote-text{
            font-size: 16px;
            font-weight: 600;
            line-height: 1.625;
            color: #222222;
            font-style: normal;
        }
    }
}

.blog-tag-list {
    a {
        color: #2f2f2f;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        font-weight: 500;
        line-height: 1.58;
        font-size: 15px;
        position: relative;
        padding-left: 5px;
        & + a {
            &::before {
                position: absolute;
                content: ",";
                left: 0;
            }
        }
        &:hover {
            color: $theme-color;
        }
    }
}

.author-social {
    margin: 0 -4px;
    margin-left: 12px;
    a {
        display: inline-block;
        margin: 0 4px;
        background: $theme-color;
        width: 25px;
        height: 25px;
        line-height: 25px;
        color: #fff;
        border-radius: 100%;
        text-align: center;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        &.facebook {
            background: #3b5999;
        }
        &.twitter {
            background: #57aced;
        }
        &.google-plus {
            background: #dc143c;
        }
    }
}

/*========================
    BLog Comment
==========================*/

.commnent-list-wrap{
    .comment{
        list-style-type: none;
        margin-top: 48px;
        padding-top: 37px;
        padding-left: 6px;
        border-top: 1px solid #eee;
        .thumb{
            float: left;
            @media #{$sm-layout} {
                float: none;
            }
            img{
                border-radius: 100%;
                width: 100px;
                height: 100px;
            }
        }
        .content{
            position: relative;
            overflow: hidden;
            margin-left: 152px;
            @media #{$sm-layout} {
                margin-left: 0;
                margin-top: 30px;
            }
            .reply-btn{
                a{
                    color: #ffffff;
                    -webkit-transition: 0.4s;
                    -o-transition: 0.4s;
                    transition: 0.4s;
                    display: inline-block;
                    font-size: 12px;
                    border: 1px solid #222222;
                    background: #222222;
                    padding: 3px 10px;
                    transition: 0.3s;
                    &:hover{
                        color: $theme-color;
                        border: 1px solid $theme-color;
                        background: transparent;
                    }
                }
            }
            .comment-footer{
                span{
                    font-size: 12px;
                    font-weight: 500;
                    display: inline-block;

                }
            }
        }
        &.comment-reply{
            padding-left: 61px;
            @media #{$sm-layout} {
                padding-left: 30px;
            }
            @media #{$large-mobile} {
                padding-left: 0;
            }
        }
    }
}

.comment-form-wrapper{
    .blog-btn{
        a{
            background: $theme-color;
            display: inline-block;
            color: #fff;
            padding: 0 36px;
            height: 55px;
            line-height: 55px;
            font-size: 14px;
            border-radius: 6px;
            -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
            font-weight: 600;
            &:hover {
                -webkit-transform: translateY(-3px);
                -ms-transform: translateY(-3px);
                transform: translateY(-3px);
            }
        }
    }
}

/* ====================
    Contact Form
======================*/
.comment-form-wrapper{
    .contact-form{
        input{
            height: 50px;
            border: 1px solid #eee;
        }
        textarea{
            color: #777;
            border-color: #eee;
            background-color: #fff;
            height: 150px;
            width: 100%;
            outline: none;
            border: 1px solid #eee;
            border-radius: 0;
            padding: 12px 20px 3px;
            max-width: 100%;
            -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
}
