/*=======================
    Header Area
========================*/




//NAVBAR MATERIAL MUI
.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    /* box-sizing: border-box; */
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: static;
    background-color: #1976d2;
    color: #fff;
}
.css-1x7skt0 {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    position: static;
    background-color: rgb(25, 118, 210);
    color: rgb(255, 255, 255);
}
#ButtonNavBar{
    border-radius: 4px;
    font-size: 13px;
    margin-left: 9px;
}

.css-1t6c9ts{
    justify-content: end !important;
}
.css-advny6-MuiPopper-root {
    z-index: 1200 !important;
}

//-----------------


.color-picker{
    right: -190px;
}


#styleLogo{
    width: 110px;
    margin-left: 30px;
}
.app-header{
    &.header--transparent{
        left: 0;
        position: absolute;
        right: 0;
        top: 30px;
        z-index: 2;

        @media #{$lg-layout} {
            top: 60px;
        }

        @media #{$md-layout} {
            top: 38px;
        }

        @media #{$sm-layout} {
            top: 38px;
        }
    }
    .button-default {
        font-size: 11px;
        line-height: 32px;
        padding: 0 16px;
        background-color: #ffffff;
        color: #424242;
        height: 32px;
        &:hover{
            background-color: $theme-color;
            color: #ffffff;
        }
    }
}

.avatar {
    display: flex;
    text-align: center;
    .text{
        margin-top: 5px;
        display: grid;
        margin-left: 10px;
    }
}

.mainmenu-wrapper{
    display: flex;
    justify-content: flex-end;
}
.main-menu{
    display: flex;
    margin: 0;
    li{
x        a{
            color: #ffffff;
            display: block;
            font-family: "Raleway",sans-serif;
            font-size: 16px;
            font-weight: 800;
            line-height: 26px;
            margin-right: 24px;
            padding: 0 1px;
            position: relative;
            transition: all 0.3s ease 0s;
            text-decoration: none;
            text-transform: uppercase;
            &::before{
                background: $theme-color none repeat scroll 0 0;
                bottom: 0;
                content: "";
                height: 0;
                left: 50%;
                position: absolute;
                right: 50%;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: left, right;
                transition-property: left, right;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
                z-index: -1;
            }
            &:hover{
                &::before{
                    left: 0;
                    right: 0;
                    height: 2px;
                }
            }
        }
        &.active{
            a{
                &::before{
                    left: 0;
                    right: 0;
                    height: 2px;
                }
            }
        }
    }


}
.app-header.stick {
    background: #424242 none repeat scroll 0 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    padding: 25px 0;
    position: fixed;
    top: 0;
    z-index: 99;
    margin-bottom: 38px;
}
.tap-top {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 150px;
    right: 30px;
    z-index: 99;
    color: white;
    text-align: center;
    background: $theme-color;
    border-radius: 100%;
    font-size: 22px;
    cursor: pointer;
    line-height: 2.2;
    display: none;
    border: 1px solid white;
}


@media only screen and (max-width: 990px) {
    #styleLogo{
        width: 110px;
    }

    #ButtonNavBar{
        display: none;
    }

}
//@media only screen and (max-width: 1000px) {
//    #styleLogo{
//        width: 50%;
//    }
//}
//@media only screen and (max-width: 1220px) {
//    #styleLogo{
//        width: 40%;
//    }
//}




